import React, { useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { FlexContainer } from 'styles/FlexContainer';
import { SourcesContainer, SourcesGroupContainer } from './styles';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { SourcesItem } from './SourcesItem';
import { NoopValueType } from 'types/global';
import { OptionsSourceMenu, SourcesListData } from 'modules/ui/SourceList/types';
import { useModalState } from 'utils/hooks/modalState';
import { LoadingModal } from 'modules/ui/modals/LoadingModal';
import { Option } from 'components/shared/ui/menu/MenuIconList/types';
import { DeleteIcon, EditIcon, UpdateIcon } from 'assets/icons/withContainer';
import { AccessGroupIcon } from 'assets/icons/access';

interface SourcesListProps {
  title: string;
  data: SourcesListData[];
  onConnection: NoopValueType<{ sourceId: string; name: string }>;
  onDelete?: NoopValueType<string>;
  onUpdate: NoopValueType<string>;
  onAccessSourceModal?: NoopValueType<string>;
  onEdit: (id: string, type: string) => void;
  loading: boolean;
  activeSourceId?: string | null;
}

export const SourcesList = ({
  data,
  onConnection,
  onDelete,
  onEdit,
  onUpdate,
  onAccessSourceModal,
  title,
  loading,
  activeSourceId,
}: SourcesListProps) => {
  const { isOpen, onOpen, onClose } = useModalState();

  const onItemConnection = useCallback(
    (id: string, name: string) => {
      const selectedItem = data.find((item) => item.id === id);

      if (selectedItem && selectedItem.loading) {
        onOpen();
      }

      if (!selectedItem?.loading) {
        onConnection({ sourceId: id, name: name });
      }
    },
    [onConnection, onOpen, data],
  );

  const optionsSourceMenu = useCallback(
    ({ id, disabledEditButton, disabledOnDelete, disabledUpdateButton, type }: OptionsSourceMenu): Option[] => [
      {
        name: 'Изменить параметры',
        value: 'edit',
        Icon: EditIcon,
        onClick: () => type && onEdit(id, type),
        disabled: !!disabledEditButton && !loading,
      },
      {
        name: 'Настроить доступы',
        value: 'accesses',
        Icon: AccessGroupIcon,
        onClick: () => onAccessSourceModal && onAccessSourceModal(id),
      },
      {
        name: 'Обновить данные',
        value: 'update',
        Icon: UpdateIcon,
        onClick: () => onUpdate(id),
        disabled: !!disabledUpdateButton,
      },
      {
        name: 'Удалить данные из проекта',
        value: 'delete',
        Icon: DeleteIcon,
        onClick: () => onDelete && onDelete(id),
        disabled: !!disabledOnDelete && !loading,
      },
    ],
    [loading, onDelete, onEdit, onAccessSourceModal, onUpdate],
  );

  return (
    <SourcesContainer>
      <SourcesGroupContainer padding="12px">
        <PrimaryTextSpan lineHeight="12px" fontSize="12px" color={`var(${ColorVarsEnum.Level_2})`}>
          {title}
        </PrimaryTextSpan>
      </SourcesGroupContainer>
      {loading && (
        <FlexContainer width="100%" height="100%" alignItems="center" justifyContent="center">
          <CircularProgress />
        </FlexContainer>
      )}
      {!loading &&
        data?.map(
          ({
            id,
            name,
            type,
            loading,
            progress,
            hasError,
            filePath,
            disabledOnDelete,
            disabledFooter,
            disabledEditButton,
            Icon,
          }) => {
            const isActiveColor = activeSourceId === id;

            const fileNameValue = type === 'file' ? { fileName: filePath || '' } : ({} as { fileName: never });

            return (
              <div key={id}>
                <SourcesItem
                  name={name}
                  disabledEditButton={disabledEditButton}
                  disabledFooter={disabledFooter}
                  disabledOnDelete={disabledOnDelete}
                  Icon={Icon}
                  onConnection={() => onItemConnection(id, name)}
                  loading={loading}
                  isActiveColor={isActiveColor}
                  isValid={hasError}
                  optionsSourceMenu={() =>
                    optionsSourceMenu({
                      id,
                      disabledEditButton,
                      disabledUpdateButton: disabledFooter,
                      disabledOnDelete,
                      type,
                    })
                  }
                  {...fileNameValue}
                />
                <LoadingModal loading={loading} progress={progress} onClose={onClose} isOpen={isOpen} />
              </div>
            );
          },
        )}
    </SourcesContainer>
  );
};
