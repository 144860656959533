import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { DateIcon } from 'assets/icons/editor';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import React, { useMemo } from 'react';
import { ErrorMessageInterface, FC } from 'types/global';
import { ModalUniversal } from 'modules/ui/ModalUniversal';
import { useModalState } from 'utils/hooks/modalState';
import { useLocalValues } from 'utils/hooks/localValues';
import {
  codeEditorTitle,
  generateMetricAstMapData,
  getIncisionsChanges,
  getIndicatorsChanges,
  getServiceChanges,
} from 'modules/settingsContainer/common/data/SqlSettings/cosntants';
import { SqlAutocomplete, SqlAutocompleteProps } from 'modules/ui/SqlAutocomplete';
import { AdviceListProps, SqlEditorProps, SqlSectionProps, SqlSettingsProps, TitledFieldInterface } from './types';
import { LocalValuesInterface, SqlContainer, SqlEditorContainer } from 'modules/settingsContainer/common/data/SqlSettings/styles';
import { Button } from 'modules/ui';
import Snackbar from 'services/Snackbar';
import { ListWithSearch } from 'modules/settingsContainer/SearchList/ListWithSearch';
import { usePasteToSqlEditor } from 'modules/visualisations/hooks/pasteToSqlEditor';
import { ListItem } from 'modules/ui/lists/MapList/item/ListItem';
import { formatSql } from 'utils/SQL/formatSQL';
import { getActiveThemeCodeEditorTheme } from 'store/reducers/themes/getters';
import { useSelector } from 'react-redux';
import { useModelIdValue } from 'utils/hooks/visualisation/modelIdValue';
import { getModelFromById } from 'store/reducers/models/getters';

const TitledField: FC<TitledFieldInterface> = ({ children, title, afterTitleSlot }) => {
  return (
    <FlexContainer
      style={{
        maxHeight: '100%',
        gap: '8px',
        flex: '1 1 0',
        overflow: 'hidden',
        flexDirection: 'column',
      }}
    >
      <FlexContainer alignItems="center" justifyContent="space-between" minHeight="24px">
        <FlexContainer alignItems="center" gap="8px">
          <PrimaryTextSpan fontSize="12px" lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`}>
            {title}
          </PrimaryTextSpan>
          {afterTitleSlot}
        </FlexContainer>
      </FlexContainer>
      {children}
    </FlexContainer>
  );
};

interface SqlFieldProps extends Pick<SqlAutocompleteProps, 'handleChange' | 'value' | 'adviceEditor' | 'onFocus'> {
  autoHeight?: boolean;
  height?: string;
}

export const SqlField = ({ height, autoHeight, ...sqlAutocompleteProps }: SqlFieldProps) => {
  const autocompleteProps = {
    ...sqlAutocompleteProps,
    autofocus: true,
    ...(height ? { height } : { autoHeight }),
  };

  return <SqlAutocomplete {...autocompleteProps} />;
};

export const AdviceList = ({ title, list }: AdviceListProps) => {
  return list && list.length ? (
    <FlexContainer flexDirection="column" gap="8px">
      <PrimaryTextSpan fontSize="12px" lineHeight="14px" color={`var(${ColorVarsEnum.Level_1})`} fontWeight={700}>
        {`${title}:`}
      </PrimaryTextSpan>
      <FlexContainer flexDirection="column" gap="10px" padding="0 8px 0">
        {list?.map((el) => (
          <PrimaryTextSpan
            key={el.caption}
            fontSize="12px"
            lineHeight="14px"
            color={`var(${ColorVarsEnum.Level_1})`}
            fontWeight={400}
          >
            {el.caption}
          </PrimaryTextSpan>
        ))}
      </FlexContainer>
    </FlexContainer>
  ) : null;
};

export const SqlSection = ({
  sqlEditorSlot,
  adviceSlot,
  modelMetaData,
  onSaveEditor,
  hasChanges,
  onCloseEditor,
  onFormatClick,
}: SqlSectionProps) => {
  const { setSelectedColumns, selectedColumns, onEditorFocus, onAddToEditor } = usePasteToSqlEditor();

  const modelMetaDataList = modelMetaData?.map(({ columns, alias }) => ({
    items: columns?.map(({ name, type }) => ({ title: name, type })),
    title: alias,
    type: alias,
  }));

  return (
    <FlexContainer width="100%" flex="1 1 0" flexDirection="column" padding="16px" gap="8px">
      <FlexContainer width="100%" flex="1 1 0" gap="32px">
        {modelMetaData && !!modelMetaData.length && (
          <FlexContainer width="300px" flexDirection="column">
            <FlexContainer flexDirection="column" flex="1 1 0">
              <TitledField title="Карта модели">
                <FlexContainer width="100%" flex="1 1 0" flexDirection="column">
                  <ListWithSearch
                    modelMetaData={modelMetaDataList || []}
                    onSelectItem={setSelectedColumns}
                    selectedOptions={selectedColumns}
                    renderItem={({ item, key, isSelected, onSelectChange }) => (
                      <ListItem
                        key={key}
                        id={item.id}
                        title={item.title}
                        Icon={item.Icon}
                        disabled={item.disabled}
                        onChecked={onSelectChange}
                        isChecked={isSelected}
                      />
                    )}
                  />
                  <FlexContainer alignItems="center" justifyContent="center" padding="20px 0 0 0">
                    <Button
                      disabled={!selectedColumns.length}
                      text="Добавить в текст"
                      heightSize="normal"
                      onClick={onAddToEditor}
                    />
                  </FlexContainer>
                </FlexContainer>
              </TitledField>
            </FlexContainer>
          </FlexContainer>
        )}
        <FlexContainer flex="1 1 0" gap="16px" maxHeight="100%">
          <FlexContainer flexDirection="column" justifyContent="space-between" width="100%" maxHeight="100%" flex="1 1 0">
            {sqlEditorSlot({ onEditorFocus })}
            <FlexContainer justifyContent="space-between" marginTop="12px">
              <Button text="Форматировать" heightSize="normal" width="auto" onClick={onFormatClick} needBackground={false} />
              <FlexContainer justifyContent="flex-end">
                <FlexContainer marginRight="10px">
                  <Button text="Отменить" heightSize="normal" width="95px" onClick={onCloseEditor} needBackground={false} />
                </FlexContainer>
                <Button disabled={!hasChanges} text="Сохранить" heightSize="normal" width="95px" onClick={onSaveEditor} />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        {adviceSlot && (
          <FlexContainer flexDirection="column" width="230px" gap="24px">
            {adviceSlot}
          </FlexContainer>
        )}
      </FlexContainer>
    </FlexContainer>
  );
};

const SqlEditor = ({
  serviceTitle,
  localValues,
  setLocalValues,
  adviceEditorIncision,
  onEditorFocus,
  adviceEditorIndicator,
  adviceEditorService,
  fromQuery,
}: SqlEditorProps) => {
  const codeEditorTheme = useSelector(getActiveThemeCodeEditorTheme);

  const filterComment = () => {
    /*TODO добавить как будет таска на исправление\n${fromQuery}\n*/
    let text = `Опишите группировки и фильтры\n`;
    if (fromQuery === null) {
      text = 'Опишите группировки и фильтры\n';
    }
    return text;
  };

  return (
    <>
      <PrimaryTextSpan fontSize="12px" lineHeight="10px" color={`var(${ColorVarsEnum.Level_3})`} marginBottom="12px">
        Выражение
      </PrimaryTextSpan>
      <SqlContainer codeEditorTheme={codeEditorTheme}>
        {!serviceTitle && (
          <>
            <SqlEditorContainer>
              <FlexContainer>
                <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`} fontSize="14px" marginLeft="4px">
                  {'SELECT'}
                </PrimaryTextSpan>
              </FlexContainer>
              <SqlAutocomplete value={`Выражение для разрезов\n`} autoHeight readOnly />
              <SqlAutocomplete
                handleChange={(incisionRequest) => setLocalValues({ ...localValues, incisionRequest })}
                value={localValues.incisionRequest || ''}
                adviceEditor={adviceEditorIncision}
                onFocus={onEditorFocus}
                autoHeight
              />
            </SqlEditorContainer>
          </>
        )}

        {!serviceTitle && (
          <>
            <SqlEditorContainer>
              <SqlAutocomplete value={`\nВыражение для показателей\n`} autoHeight readOnly />
              <SqlAutocomplete
                handleChange={(indicatorRequest) => setLocalValues({ ...localValues, indicatorRequest })}
                value={localValues.indicatorRequest || ''}
                adviceEditor={adviceEditorIndicator}
                onFocus={onEditorFocus}
                autoHeight
              />
            </SqlEditorContainer>
          </>
        )}

        {serviceTitle && (
          <>
            <SqlEditorContainer>
              <SqlAutocomplete value={`Выражение для сервисов\n`} autoHeight readOnly />
              <SqlAutocomplete
                handleChange={(serviceRequest) => setLocalValues({ ...localValues, serviceRequest })}
                value={localValues.serviceRequest || ''}
                adviceEditor={adviceEditorService}
                onFocus={onEditorFocus}
                autoHeight
              />
            </SqlEditorContainer>
          </>
        )}

        <SqlEditorContainer>
          <FlexContainer>
            <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_3})`} fontSize="14px" marginLeft="4px" marginTop="20px">
              {fromQuery}
            </PrimaryTextSpan>
          </FlexContainer>
          <SqlAutocomplete value={filterComment()} readOnly autoHeight />
          <SqlAutocomplete
            handleChange={(filterAndGroupRequest) => setLocalValues({ ...localValues, filterAndGroupRequest })}
            value={localValues.filterAndGroupRequest || ''}
            adviceEditor={adviceEditorIndicator}
            onFocus={onEditorFocus}
            autoHeight
          />
        </SqlEditorContainer>
      </SqlContainer>
    </>
  );
};

export const SqlSettings = ({
  sqlData: { incisionSqlString, indicatorSqlString, filterAndGroupSqlString },
  astData,
  adviceEditorIncision,
  adviceEditorIndicator,
  adviceEditorService,
  serviceTitle,
  serviceSqlString,
  onSave,
  modelMetaData,
  modelId,
}: SqlSettingsProps) => {
  const { isOpen, onOpen, onClose } = useModalState();
  const modelIdValue = useModelIdValue('');
  const fromQuery = useSelector(getModelFromById(modelIdValue))?.from || '';

  const defaultRequests = useMemo(
    () => ({
      incisionRequest: incisionSqlString,
      indicatorRequest: indicatorSqlString,
      filterAndGroupRequest: filterAndGroupSqlString,
      serviceRequest: serviceSqlString,
    }),
    [incisionSqlString, indicatorSqlString, filterAndGroupSqlString, serviceSqlString],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const incisionsAstMapData = useMemo(() => generateMetricAstMapData(astData.incisions), [JSON.stringify(astData.incisions)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const indicatorsAstMapData = useMemo(() => generateMetricAstMapData(astData.indicators), [JSON.stringify(astData.indicators)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const servicesAstMapData = useMemo(() => generateMetricAstMapData(astData.variables), [JSON.stringify(astData.variables)]);

  const onChange = ({ incisionRequest, indicatorRequest, serviceRequest, filterAndGroupRequest }: typeof defaultRequests) => {
    try {
      const incisionsChanges = getIncisionsChanges({ incisionsAstMapData, incisionRequest });
      const indicatorsChanges = getIndicatorsChanges({ indicatorsAstMapData, indicatorRequest });
      const servicesChanges = getServiceChanges({ servicesAstMapData, serviceRequest });

      console.log(incisionsChanges, 'Разрезы');
      console.log(indicatorsChanges, 'Показатели');
      console.log(servicesChanges, 'Переменные');

      onSave({ incisionsChanges, indicatorsChanges, servicesChanges, filterAndGroupRequest: filterAndGroupRequest || '' });
    } catch (error) {
      const message = (error as ErrorMessageInterface)?.message || '';
      Snackbar.show(message, 'error');
      throw Error(message);
    }
  };

  const {
    localValues,
    setLocalValues,
    onSave: onLocalSave,
    onCancel,
    hasChanges,
  } = useLocalValues({
    value: defaultRequests,
    onChange,
  });

  const formatSqlValues = (): LocalValuesInterface => {
    const keysToFormat: Array<keyof LocalValuesInterface> = [
      'incisionRequest',
      'indicatorRequest',
      'serviceRequest',
      'filterAndGroupRequest',
    ];
    const formattedValues: LocalValuesInterface = {};

    Object.entries(localValues).forEach(([key, value]) => {
      if (keysToFormat.includes(key as keyof LocalValuesInterface) && value) {
        formattedValues[key as keyof LocalValuesInterface] = formatSql(value);
      }
    });

    return formattedValues;
  };

  const onFormatClick = () => {
    const { incisionRequest, indicatorRequest, serviceRequest, filterAndGroupRequest } = formatSqlValues();

    setLocalValues({
      incisionRequest,
      indicatorRequest,
      serviceRequest,
      filterAndGroupRequest,
    });
  };

  const onCloseEditor = () => {
    onClose();
    onCancel();
  };

  const onSaveEditor = () => {
    onFormatClick();
    onLocalSave();
    onClose();
  };

  return (
    <>
      <MainContainerSettings titleText="SQL" ButtonIcon={DateIcon} onClickButtonIcon={onOpen} />
      <ModalUniversal
        open={isOpen}
        onClose={onCloseEditor}
        headerText={codeEditorTitle}
        width="95vw"
        maxHeight="95vh"
        disableEscapeKeyDown
      >
        <SqlSection
          sqlEditorSlot={({ onEditorFocus }) => (
            <SqlEditor
              serviceTitle={serviceTitle}
              localValues={localValues}
              setLocalValues={setLocalValues}
              adviceEditorIncision={adviceEditorIncision}
              onEditorFocus={onEditorFocus}
              adviceEditorIndicator={adviceEditorIndicator}
              adviceEditorService={adviceEditorService}
              fromQuery={fromQuery}
            />
          )}
          adviceSlot={
            <>
              <AdviceList title="Разрезы" list={adviceEditorIncision} />
              <AdviceList title="Показатели" list={adviceEditorIndicator} />
              <AdviceList title={serviceTitle || ''} list={adviceEditorService} />
            </>
          }
          onFormatClick={onFormatClick}
          modelMetaData={modelMetaData}
          hasChanges={hasChanges}
          onSaveEditor={onSaveEditor}
          onCloseEditor={onCloseEditor}
        />
      </ModalUniversal>
    </>
  );
};
